.registrationDetails {
    cursor: pointer;
}

.modalContainer {
    min-width: 20vw;
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
}

.modalHeader {
    flex: 1 1 auto;
    border-top: 4px solid rgb(0, 120, 212);
    color: rgb(0, 120, 212);
    display: flex;
    align-items: center;
    font-weight: bolder;
    padding: 12px 12px 14px 24px;
}

.modalBody {
    flex: 4 4 auto;
    padding: 0 24px 24px 24px;
    overflow-y: 'hidden';
}

.registrationWeek {
    padding: 0.8rem;
    background-color: rgb(228, 230, 243);
    border-radius: 30px;
    margin-top: 1.5rem;
    font-weight: bolder;
    font-size: 1.2rem;
}

.failedFilter {
    padding: 5rem;
    text-align: center;
    font-weight: bolder;
    font-size: 1.5rem;
}


.text {
    font-weight: bolder;
    margin-right: 2rem;
}


.optionsPanel {
    display: flex;
    flex-direction: row;
    width: 30%;
    justify-content: flex-start;
    align-items: center;
}

.optionsPanel :nth-child(2) {
    margin-right: 1rem;
}

.tableContainer {
    margin: 0.6% auto;
    width: 100%;
}

.yearContainer {
    margin-bottom: 2rem;
}

table {
    border: 0.5px solid rgb(245, 245, 245);

}

thead {
    background-color: rgb(41, 54, 74);
    color: white;
    border-radius: 30px;
    font-weight: normal;
}

tr:nth-child(even) {
    background-color: rgb(245, 245, 245);
}

td,
th {
    padding: 1rem;
    white-space: nowrap;
}


.totalPriceContainer {
    width: 100%;
    margin-top: 0.8rem;
    display: flex;
    flex-direction: column;

}

.priceContainer {
    display: flex;
    flex-direction: row;
    margin-top: 0.3rem;
}

@media only screen and (max-width: 1300px) {
    .optionsPanel {
        width: 100%;
    }

}