.pricePanel{
    font-size: 1.2rem;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input{
    width: 72%;
}

.container{
    margin-top: 2rem;
}

.inputContainer{
    display: flex;
    justify-content: space-between;
    flex-direction: row;
}

.priceInfoContainer{
    display: flex;
    flex-direction: row;
    margin-bottom: 2rem;
    justify-content: flex-end;
}

.btnSave{
    padding: 1.1rem;
}

.price{
    font-weight: bolder;
    color: rgb(41,54,74);
    margin-left: 0.5rem;

}