.dialogContainer {
    min-width: 30%;
    min-height: 35%;
    width: 100%;
}

.warning {
    text-align: center;
    color: red;
    padding: 1rem;
    font-size: 1.2rem;
    font-weight: bolder;
}

.modalBody {
    flex: 4 4 auto;
    padding: 0 24px 24px 24px;
    overflow-y: 'hidden';
}