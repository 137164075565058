.navBar{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.logon{
    display: flex;
    flex-direction: row;
    color: white;
}
.accountName{
    margin-left: 0.2rem;

}